import { PageTypes, Theme, ThemeContext } from "assets";
import { graphql, useStaticQuery } from "gatsby";
import { convertHero } from "molecules";
import { convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

const InternalHowWeKnow = (props) => {
  const howWeKnowData = props?.craftAPI?.entry;

  const children = convertComponentList(howWeKnowData?.componentList);

  // Place the hero at the top of the page
  children.unshift(convertHero(howWeKnowData));

  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout
        pageType={PageTypes.HOW_WE_KNOW}
        theme={Theme.Black}
        title={howWeKnowData.title}
      >
        {children}
      </PageLayout>
    </ThemeContext.Provider>
  );
};

InternalHowWeKnow.propTypes = {
  craftAPI: PropTypes.shape({
    entry: PropTypes.shape({}),
  }),
};

const HowWeKnow = (props) => {
  const howWeKnowQuery = useStaticQuery(graphql`
    query {
      craftAPI {
        entry(type: "howWeKnow") {
          ... on CraftAPI_howWeKnow_howWeKnow_Entry {
            id
            componentList {
              ...ComponentListFragment
            }
            coverImage {
              ...ImageMetadataFragment
            }
            coverImageCropStyle
            coverImagePositionOverride
            longDescription
            shortDescription
            heroType
            title
          }
        }
      }
    }
  `);

  return <InternalHowWeKnow {...howWeKnowQuery} {...props} />;
};

export default HowWeKnow;
